import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "notrecovery" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_search = _resolveComponent("page-search")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_page_search, {
      searchFormConfig: _ctx.searchFormConfig,
      onResetBtnClick: _ctx.handleResetClick,
      onQueryBtnClick: _ctx.handleQueryClick
    }, null, 8, ["searchFormConfig", "onResetBtnClick", "onQueryBtnClick"]),
    _createVNode(_component_page_content, {
      ref: "pageContentRef",
      contentTableConfig: _ctx.contentTableConfig,
      pageName: "notrecovery",
      showNewBtn: false,
      showRightBtn: false,
      showReceiptBtn: true,
      showRecoveryBtn: true,
      onRecoveryBtnClick: _ctx.handleRecoveryClick,
      onReceiveBtnClick: _ctx.handleReceiveClick,
      onPostBtnClick: _ctx.handlePostClick,
      onReceiptData: _ctx.receiptData
    }, {
      recovery: _withCtx((scope) => [
        _createVNode(_component_el_button, {
          plain: "",
          size: "mini",
          type: scope.row.recoverystate === '已回收' ? 'success' : 'danger'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(scope.row.recoverystate), 1)
          ]),
          _: 2
        }, 1032, ["type"])
      ]),
      issue: _withCtx((scope) => [
        _createVNode(_component_el_button, {
          plain: "",
          size: "mini",
          type: scope.row.issuestate === '已接收' ? 'success' : 'danger'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(scope.row.issuestate), 1)
          ]),
          _: 2
        }, 1032, ["type"])
      ]),
      post: _withCtx((scope) => [
        _createVNode(_component_el_button, {
          plain: "",
          size: "mini",
          type: scope.row.poststate === '已寄出' ? 'success' : 'danger'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(scope.row.poststate), 1)
          ]),
          _: 2
        }, 1032, ["type"])
      ]),
      _: 1
    }, 8, ["contentTableConfig", "onRecoveryBtnClick", "onReceiveBtnClick", "onPostBtnClick", "onReceiptData"])
  ]))
}
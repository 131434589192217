export const contentTableConfig = {
  title: '未回收回单列表',
  propList: [
    { prop: 'oddnumber', label: '运单号', minWidth: '140' },
    {
      prop: 'recoverystate',
      label: '回收状态',
      minWidth: '120',
      slotName: 'recovery'
    },
    {
      prop: 'issuestate',
      label: '发放状态',
      minWidth: '120',
      slotName: 'issue'
    },
    { prop: 'poststate', label: '寄出状态', minWidth: '120', slotName: 'post' },
    {
      prop: 'recoverynumber',
      label: '回单数量',
      minWidth: '100'
    },
    {
      prop: 'consignor',
      label: '发货人',
      minWidth: '100'
    },
    {
      prop: 'consignee',
      label: '收货人',
      minWidth: '100'
    },
    {
      prop: 'goodsname',
      label: '货物名称',
      minWidth: '100'
    },
    {
      prop: 'goodsnumber',
      label: '货物数量',
      minWidth: '100'
    }
  ],
  showIndexColumn: true,
  showSelectColumn: true
}
